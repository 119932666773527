.masthead {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: white;
  background-size: cover;
  background-position: center;

  .btn {
    margin: 10px 20px 0px 0px;
  }

  .header-content {
    max-width: 48rem;
    margin-bottom: 6rem;
    text-align: center;

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .device-container {
    max-width: 20rem;
    margin-right: auto;
    margin-left: auto;

    .screen img {
      border-radius: 3px;
    }
  }
}

.join .masthead {
  padding-top: 20vh;
}

.webp .masthead {
  background-image: url("../img/2019/bg_top.webp");
}

.no-webp .masthead {
  background-image: url("../img/2019/bg_top.jpg");
}

.masthead.annonsor {
  background-image: url("../img/2019/bg_top.jpg");
}

.webp .masthead.bgtop2 {
  background-image: url("../img/2019/bg_top2-sm.webp");
}

.no-webp .masthead.bgtop2 {
  background-image: url("../img/2019/bg_top2-sm.jpg");
}

.webp .masthead.bgtop3 {
  background-image: url("../img/2019/bg_top3-sm.webp");
}

.no-webp .masthead.bgtop3 {
  background-image: url("../img/2019/bg_top3-sm.jpg");
}

.webp .masthead.bgtop4 {
  background-image: url("../img/2019/bg_top4-sm.webp");
}

.no-webp .masthead.bgtop4 {
  background-image: url("../img/2019/bg_top4-sm.jpg");
}

.webp .masthead.bgtop5 {
  background-image: url("../img/2019/bg_top5-sm.webp");
}

.no-webp .masthead.bgtop5 {
  background-image: url("../img/2019/bg_top5-sm.jpg");
}

.webp .masthead.bgtop6 {
  background-image: url("../img/2019/bg_top6-sm.webp");
}

.no-webp .masthead.bgtop6 {
  background-image: url("../img/2019/bg_top6-sm.jpg");
}

@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
  .webp .masthead.bgtop2 {
    background-image: url("../img/2019/bg_top2-sm-2x.webp");
  }
  
  .no-webp .masthead.bgtop2 {
    background-image: url("../img/2019/bg_top2-sm-2x.jpg");
  }
  
  .webp .masthead.bgtop3 {
    background-image: url("../img/2019/bg_top3-sm-2x.webp");
  }
  
  .no-webp .masthead.bgtop3 {
    background-image: url("../img/2019/bg_top3-sm-2x.jpg");
  }
  
  .webp .masthead.bgtop4 {
    background-image: url("../img/2019/bg_top4-sm-2x.webp");
  }
  
  .no-webp .masthead.bgtop4 {
    background-image: url("../img/2019/bg_top4-sm-2x.jpg");
  }
  
  .webp .masthead.bgtop5 {
    background-image: url("../img/2019/bg_top5-sm-2x.webp");
  }
  
  .no-webp .masthead.bgtop5 {
    background-image: url("../img/2019/bg_top5-sm-2x.jpg");
  }
  
  .webp .masthead.bgtop6 {
    background-image: url("../img/2019/bg_top6-sm-2x.webp");
  }
  
  .no-webp .masthead.bgtop6 {
    background-image: url("../img/2019/bg_top6-sm-2x.jpg");
  }
}

.join .joinheader {
  padding-top: 4vh;

  @include media-breakpoint-up(lg) {
    padding-top: 6vh;
  }
}

@include media-breakpoint-up(lg) {
  .masthead {
    height: 70vh;
    min-height: 665px;
    padding-top: 0;
    padding-bottom: 0;

    .header-content {
      margin-bottom: 0;
      text-align: left;
    }

    .device-container {
      max-width: 325px;
    }
  }

  .join .masthead {
    height: 40vh;
  }

  .webp .masthead.bgtop2 {
    background-image: url("../img/2019/bg_top2.webp");
  }
  
  .no-webp .masthead.bgtop2 {
    background-image: url("../img/2019/bg_top2.jpg");
  }
  
  .webp .masthead.bgtop3 {
    background-image: url("../img/2019/bg_top3.webp");
  }
  
  .no-webp .masthead.bgtop3 {
    background-image: url("../img/2019/bg_top3.jpg");
  }
  
  .webp .masthead.bgtop4 {
    background-image: url("../img/2019/bg_top4.webp");
  }
  
  .no-webp .masthead.bgtop4 {
    background-image: url("../img/2019/bg_top4.jpg");
  }
  
  .webp .masthead.bgtop5 {
    background-image: url("../img/2019/bg_top5.webp");
  }
  
  .no-webp .masthead.bgtop5 {
    background-image: url("../img/2019/bg_top5.jpg");
  }
  
  .webp .masthead.bgtop6 {
    background-image: url("../img/2019/bg_top6.webp");
  }
  
  .no-webp .masthead.bgtop6 {
    background-image: url("../img/2019/bg_top6.jpg");
  }

  @media 
  (-webkit-min-device-pixel-ratio: 2), 
  (min-resolution: 192dpi) { 
    .webp .masthead.bgtop2 {
      background-image: url("../img/2019/bg_top2-2x.webp");
    }
    
    .no-webp .masthead.bgtop2 {
      background-image: url("../img/2019/bg_top2-2x.jpg");
    }
    
    .webp .masthead.bgtop3 {
      background-image: url("../img/2019/bg_top3-2x.webp");
    }
    
    .no-webp .masthead.bgtop3 {
      background-image: url("../img/2019/bg_top3-2x.jpg");
    }
    
    .webp .masthead.bgtop4 {
      background-image: url("../img/2019/bg_top4-2x.webp");
    }
    
    .no-webp .masthead.bgtop4 {
      background-image: url("../img/2019/bg_top4-2x.jpg");
    }
    
    .webp .masthead.bgtop5 {
      background-image: url("../img/2019/bg_top5-2x.webp");
    }
    
    .no-webp .masthead.bgtop5 {
      background-image: url("../img/2019/bg_top5-2x.jpg");
    }
    
    .webp .masthead.bgtop6 {
      background-image: url("../img/2019/bg_top6-2x.webp");
    }
    
    .no-webp .masthead.bgtop6 {
      background-image: url("../img/2019/bg_top6-2x.jpg");
    }
  }
}