// Import fonts
$headings-font-family: 'Open Sans', sans-serif;
$headings-font-weight: 700;
$font-weight-bold: 700;

$font-family-base: 'Open Sans', sans-serif;
$font-size-base: 16;
$font-weight-normal: 300;

$font-family-sans-serif: 'Open Sans', sans-serif;
$enable-responsive-font-sizes: true;

$primary:       #3F7021;
$secondary:     #5dc62f;
$pink:          #fe6672;
$warning:       #946b2d;
$dark:          #474747;
$adr-gray:      #a5a5a5;
$danger:        #721A62;

// Navbar
$navbar-light-color: #222;
$navbar-light-hover-color: $secondary;
$navbar-light-active-color: $primary;

// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// Default all
// @import "../../node_modules/bootstrap/scss/bootstrap";

// Fonts
$fa-font-path: '../webfonts';
// @import '@fortawesome/fontawesome-free/scss/variables';
// @import '@fortawesome/fontawesome-free/scss/mixins';
// @import '@fortawesome/fontawesome-free/scss/core';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
// @import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
// @import '@fortawesome/fontawesome-free/scss/v4-shims';

// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Optional
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/images";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/close";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/utilities";

// Theme
@import "theme";
@import "masthead";
@import "signup";
@import "promotions";

