/** Tags for the customer campaigns **/
.tags-container,
.lang-container {
    margin-bottom: 2em;
    span {
        @extend .btn;
        @extend .btn-light;
        margin-bottom: .5em;
        max-width: 600px;
        
    }
    
    .active,
    .active:hover {
        @extend .btn-primary;
    }
}

.campaign-all {
    img {
        @include transition(all 0.5s ease-in-out);
        
        &:hover,
        &:active {
            overflow: hidden;
            -webkit-filter: grayscale(0.5);
            filter: grayscale(0.5);
            transform: scale(1.0125);
        }
    }

    .campaign-text {
        padding: 0 1em;
        padding-bottom: 1em;
    }
    
    .desc {
        height: 155px;
        max-height: 155px;
        position: relative;
        overflow: hidden;
        font-size: .8rem;

        img {
            width: 105px;
            height: auto;
            max-height: 140px;
            padding-left: 1em;
        }
    }

    .view-more,
    .view-less {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        color: $primary;
        margin: 0;
        padding: 3rem 0 0 0;
        @include gradient-y(rgba(255,255,255,0), #fff);

        &:hover {
            cursor: pointer;
        }
    }

    .view-less {
        position: relative;
        padding-top: 1em;
    }

    &.future {
        color: $dark;

        img {
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
            @include transition(all 0.5s ease-in-out);

            &:hover,
            &:active {
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
                transform: scale(1.0125);
            }
        }
    }
}