.signup-block {
  @extend .w-100;
  @extend .mx-auto;
  @extend .d-flex;
  @extend .flex-column;
  max-width: 42em;
  margin-top: 15rem;
  margin-bottom: 15rem;

  .fas {
    color: $secondary;
  }

  input::placeholder {
    color: lighten($dark, 50%);
  }
}

body.signup,
body.login {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.webp {
  body.signup,
  body.login {
    background-image: url("../img/2019/bg_fabriksgatan-sm.webp");
  }
}

.no-webp {
  body.signup,
  body.login {
    background-image: url("../img/2019/bg_fabriksgatan-sm.jpg");
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .webp {
    body.signup,
    body.login {
      background-image: url("../img/2019/bg_fabriksgatan-sm-2x.webp");
    }
  }

  .no-webp {
    body.signup,
    body.login {
      background-image: url("../img/2019/bg_fabriksgatan-sm-2x.jpg");
    }
  }
}

@include media-breakpoint-up(lg) {
  .signup-block {
    width: 35% !important;
    margin-bottom: 25rem;
  }

  .webp {
    body.signup,
    body.login {
      background-image: url("../img/2019/bg_fabriksgatan.webp");
    }
  }

  .no-webp {
    body.signup,
    body.login {
      background-image: url("../img/2019/bg_fabriksgatan.jpg");
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .webp {
      body.signup,
      body.login {
        background-image: url("../img/2019/bg_fabriksgatan-2x.webp");
      }
    }

    .no-webp {
      body.signup,
      body.login {
        background-image: url("../img/2019/bg_fabriksgatan-2x.jpg");
      }
    }
  }
}
