html,
body {
  width: 100%;
  // height: 100%;
  scroll-behavior: smooth;
}

hr {
  margin-top: 1.5em;
}

li {
  font-weight: 100;
}

.secondary-navbar ul {
  list-style: none;
  padding: 0;
}
.secondary-navbar ul ul li {
  margin-left: 2rem;
}

.secondary-navbar li {
  background: #bfbfbf;
  margin: 3px 0;
  color: black;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.secondary-navbar li a {
  display: list-item;
  padding: 20px 10px;
  color: #fff;
}

.secondary-navbar li a:hover,
.secondary-navbar li a:active,
.secondary-navbar li a:focus {
  text-decoration: none;
  background: #ababab;
}
.sticky-top {
  top: 0;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 1rem 0.5rem;
  margin: 0 0 1rem;
  word-break: break-all;
  word-wrap: break-word;
  color: #fff;
  @extend .bg-dark;
  border-radius: 4px;
  font-size: 0.8rem;
}

code {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 0.3rem 0.5rem;
  word-break: break-all;
  word-wrap: break-word;
  color: $danger;
  border-radius: 2px;
  font-size: 0.8rem;
  @extend .bg-light;
  border: 1px solid darken($light, 5%);
}

.badge-success {
  background-color: $secondary;
  font-size: inherit;
  font-weight: inherit;
}

.table td {
  font-weight: 100;
}

hr.light {
  border-color: white;
}

h4,
h5 {
  font-weight: 400;
}

.text-small {
  font-size: 0.8rem;
  .form-control {
    font-size: 0.7rem;
  }
}

#topNav {
  border-color: rgba(34, 34, 34, 0.05);
  background-color: white;
  transition: all 0.35s;
  letter-spacing: 1px;
  z-index: 1050;
}

#mainNav {
  border-color: rgba(34, 34, 34, 0.05);
  background-color: white;
  transition: all 0.35s;
  letter-spacing: 1px;
  box-shadow: 0 -5px 10px;

  .navbar-brand {
    letter-spacing: 1px;

    img {
      max-width: 180px;
    }
  }

  .navbar-toggler {
    font-size: 70%;
    padding: 8px 10px;

    &:focus {
      outline-color: $primary;
    }
  }

  li {
    padding: 0 0.5rem;
  }

  .navbar-nav > li > a {
    font-size: 70%;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .btn-user {
    color: #fff;
  }
}

.dropdown-item {
  font-size: 70%;
}

.btn {
  @extend .btn;
  transition: all 0.35s;
  border-radius: 300px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn-user {
  background: $secondary;
  color: #fff;
  border: none;

  &:hover,
  &:focus {
    background: rgba(93, 198, 47, 0.7);
    color: #fff;
  }

  &:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: " \f007";
  }
}

.btn-xl,
.btn {
  font-size: 11px;
  padding: 15px 45px;
}

.btn-pink {
  background: $pink;
  color: #fff;

  &:hover,
  &:focus {
    background: rgba(254, 102, 114, 0.7);
    color: #fff;
  }
}

.btn-lang {
  font-size: 11px;
  color: #000 !important;
  padding: 0 !important;
}

section {
  padding: 4rem 0;
}

@include media-breakpoint-up(lg) {
  section {
    padding: 6rem 0;
  }
}

.maincontent {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  img {
    margin-bottom: 1.5rem;
  }

  p {
    line-height: 1.75;
  }
}

section.features {
  .section-heading {
    margin-bottom: 100px;

    h2 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .feature-item {
    max-width: 325px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    h3 {
      font-size: $display4-size;
    }

    i {
      font-size: 80px;
      display: block;
      margin-bottom: 15px;
      background: $secondary;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

section.influencer {
  position: relative;
  padding: 250px 0;
  background-position: center;
  background-size: cover;

  .influencer-content {
    position: relative;
    z-index: 1;

    h2 {
      font-size: $display4-size;
      margin-top: 0;
      margin-bottom: 25px;
      color: white;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.webp section.influencer {
  background-image: url("../img/2019/bg_influencer.webp");
}

.no-webp section.influencer {
  background-image: url("../img/2019/bg_influencer.jpg");
}

@include media-breakpoint-up(lg) {
  section.influencer .influencer-content h2 {
    font-size: $display3-size;
  }
}

.footer-logo {
  max-width: 200px;
  margin-bottom: 0.5rem;
}

footer {
  padding: 2rem 0;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222222;
  border-top: 3px solid $secondary;
  color: #fff;

  p {
    font-size: 0.8rem;
    margin: 0;
  }

  a {
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      color: $secondary;
      text-decoration: none;
    }
  }

  ul {
    margin-bottom: 0;
    list-style: none;
    padding: 0;
  }

  li {
    border-bottom: 1px solid #474747;
    font-size: 0.8rem;
    line-height: 2;
  }

  .btn {
    margin-top: 1rem;
  }
}

.bg-dark {
  background: #777779 !important;
}

.text-secondary {
  color: #fff !important;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.adrecord-form {
  form {
    font-weight: 100 !important;
  }

  input {
    background: transparent;
    border: none;
    color: #fff;
    min-width: 90%;

    &:-webkit-autofill,
    &:-webkit-autofill-selected,
    &:-webkit-internal-autofill,
    &:-webkit-internal-autofill-selected {
      background: transparent;
      border: none;
      -webkit-text-fill-color: #fff;
      min-width: 90%;
    }

    &:focus,
    &:active {
      border: none;
      outline: none !important;
    }

    &::placeholder {
      color: #fff;
      font-weight: 100;
    }
  }

  .form-group {
    border-bottom: 1px solid #fff;

    i {
      margin-right: 10px;
    }
  }

  .form-control {
    margin: 5px 5px auto;
    padding: 0 5px;
    background: transparent;
    color: #fff;
    border: none;
    border-radius: 0;
    font-weight: 100;
  }

  .form-control:focus {
    background: transparent;
    color: #fff;
    outline: none;
    box-shadow: none;
  }
}

.form-secondary {
  input {
    color: black;
    font-weight: inherit;
  }

  .form-group {
    background: #fff;
    padding: 5px 10px;
  }

  label,
  input::placeholder {
    color: $adr-gray;
  }
}

.btn-submit {
  display: block;
}

@include media-breakpoint-up(xl) {
  .container {
    max-width: 1250px;
  }
}

@include media-breakpoint-up(lg) {
  @-webkit-keyframes shadow-pulse {
    85% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
  }

  @keyframes shadow-pulse {
    85% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
  }

  .signup-block {
    -webkit-animation: shadow-pulse 5s ease-in-out infinite both;
    animation: shadow-pulse 5s ease-in-out infinite both;
  }
}
